export const homeObjFive = {
  id: "projects",
  lightBg: true,
  primary: false,
  imgStart: "start",
  lightTopLine: false,
  sectionDescription: "A sampling of some recent and not so recent projects. Most of these are live and active sites. Come back frequently for more updates.",
  lightText: false,
  lightTextDesc: false,
  headline: "Projects",
  description: "Web CRUD app for scheduling a neighbourhood tool rental built with Postgres, Express, React, Node.",
  description2: "Website designed and built using WordPress CMS with additional functional elements added using JS and jQuery.",
  description3: "E-commerce store built on the Shopify platform with custom features injected using Liquid templating language.",
  description4: "Custom business storefront for a boutique chocolatier, built on WordPress CMS with Elementor and jQuery.",
  description5: "Buisness website for a movement and meditation instructor built on Wordpress using GeneratePress.",
  description6:  "Redesigned organizational webplatform for the Ontario Association of Geography and Environmental Educators",
  buttonLabel: "",
  img: require("../../images/ToolSwap.jpeg"),
  img2: require("../../images/OAEA.png"),
  img3: require("../../images/StudioPothos.jpeg"),
  img4: require("../../images/MilonBackt.jpeg"),
  img5: require("../../images/Amy-Dhindsa.jpg"),
  img6: require("../../images/OAGEE-Homepage.jpg"),
  start: "",
  alt: "Image",
  viewButton: false,
};